<template>
    <v-footer class="bg-grey-darken-4" absolute height="40" style="max-height: 40px">
        <v-col class="text-center pa-2">
            {{ new Date().getFullYear() }} — <strong>ООО "Картелье" , ОГРН 1237800031644</strong>
        </v-col>
    </v-footer>
</template>

<script>
export default {
    name: 'FooterComp',

    data: () => ({}),
}
</script>

