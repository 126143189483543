import axios from 'axios'
import moment from "moment";

const pipeline = {
    "6802290": "Вх. обращения",
    "6746270": "Шаблонный дизайн",
    "6766090": "Индивидуальный дизайн",
    "6766102": "Воронка дизайнеров"
}

export default {
    search_order(context, data) {
        // console.log(context.$AMO_ADDRESS)
        // console.log('search_order ', data)
        axios({
            method: 'get',
            url: `${context.$PHP_ADDRESS}/get_leads.php`,
            params: {
                "query": data.number_order || data.number_phone
            }
        })
            .then(response => {
                let data = response.data;
                data.forEach(el => {
                    el.created_at = moment.unix(el.created_at).format("DD.MM.YYYY");
                    el["pipeline"] = pipeline[el.pipeline_id];
                    el["custom_fields"] = el["custom_fields_values"]?.map(i => {
                        return i['field_name'] + ': ' + i['values'][0]['value']
                    })

                    el["contacts_phone"] = el["contacts_phone"].map(i => {
                            return i.values.map(row => {
                                return row.value
                            }).join(', ');
                        }).join(', ')
                });
                context.search_result = data;
                context.loading = false;
                context.loading_result = false;
                // console.log('search_order ', response.data);
            })
            .catch(function (error) {
                context.search_result = null;
                context.loading = false;
                context.loading_result = false;
                context.error_404 = true;
                console.log(error.response, error);
            })
    },
    change_status(context, id, new_status, tag) {
        axios({
            method: 'get',
            url: `${context.$PHP_ADDRESS}/change_status.php`,
            params: {
                "id": id,
                "status_id": new_status,
                "tag": tag
            }
        })
            .then(() => {
                if (new_status === '142') {
                    context.flag_done = true;
                    context.selected_order = null;
                }
            })
            .catch(function (error) {
                console.log(error.response.status, error);
            })
    }
}
