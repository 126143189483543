<template>
    <v-app>
        <v-main :key="main_key">
            <StartPage v-if="$AUTH" v-on:update="update"/>
            <AuthComponent v-if="!$AUTH" v-on:update="update"></AuthComponent>
        </v-main>
        <FooterComp v-if="$AUTH"/>
    </v-app>
</template>

<script>
import StartPage from './pages/StartPage.vue'
import FooterComp from './components/Footer.vue'
import AuthComponent from "@/pages/Auth";

export default {
    name: 'App',

    components: {
        AuthComponent,
        StartPage,
        FooterComp,
    },

    data: () => ({
        main_key: 1
    }),
    mounted() {
    },
    methods: {
        update(){
            this.main_key = this.main_key + 1;
        }
    }
}
</script>

<style>
</style>