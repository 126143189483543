export default {
    $AMO_ADDRESS: 'https://cartelye.amocrm.ru/api/v4',
    $PHP_ADDRESS: 'https://cartelye-tt.site/amo',
    // $PHP_ADDRESS: 'http://localhost:63342/cart_admin/public/amo',
    $TT: [{
        $NAME_TT: 'ТРК Афимол',
        $PASS: '943b874cfeef6e8b5ed9b23a8234875d1cd75a971eb68bb9ea77f031' //afimol
    },{
        $NAME_TT: 'ТРК Галерея',
        $PASS: 'cea71eb957bc6929a383ad2d335a4f2d7896b90bd131e710757a3adf' //galerea
    },{
        $NAME_TT: 'Рязань',
        $PASS: 'dc7664ffb731e5c033ac37e4f2db8104ef58284a6605fe911408f195' //ryazan
    },{
        $NAME_TT: 'Казань',
        $PASS: 'f2c8f9a9276b5d2e3edb7f3444a0df942589fd72cb13dec2e578cc87' //kazan
    }
    ]
};
// https://crypt-online.ru/crypts/sha256/