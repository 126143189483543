<template>
    <v-form ref="form" class="w-50 mt-1">
        <span v-if="!selected_order && !flag_done">
        <v-text-field
            v-model="this.number_order"
            :counter="6"
            :rules="[rule]"
            @focus="clear_validate"
            label="Номер заказа"
            hint="6 цифр номера заказа"
            type="number"
            required
            clearable
        ></v-text-field>

        <div class="text-center">
            ИЛИ
        </div>

        <v-text-field
            v-model="this.number_phone"
            :rules="[rule]"
            @focus="clear_validate"
            label="Номер телефона покупателя"
            hint="Номер в формате 89991234567"
            required
            clearable
        ></v-text-field>

        <v-checkbox
            v-model="this.agree"
            :rules="[v => !!v || 'Не хватает подписи']"
            @focus="clear_validate"
            label="Покупатель подписал нужные документы?"
            density="compact"
            required
        ></v-checkbox>

        <div class="text-center">
            <v-btn
                size="x-large"
                prepend-icon="mdi-magnify"
                @click="validate"
            >
                ИСКАТЬ
            </v-btn>
            <v-btn
                class="ml-5" @click="clear"
                variant="text"
                size="small">
                Отчистить
            </v-btn>
        </div>

        <span class="text-center align-center justify-center">
            <h3 v-if="error_404" class="text-red mt-3">Не найдено, проверьте данные</h3>
            <v-progress-circular v-if="loading_result" indeterminate class="text-center"></v-progress-circular>
        </span>

        <v-data-table
            v-if="search_result"
            v-model:items-per-page="itemsPerPage"
            :headers="headers"
            :items="search_result"
            item-value="name"
            class="elevation-1 mt-3"
            :loading="loading"
            items-per-page-text="Элементов на странице"
        >
            <template v-slot:[`item.open`]="{item}">
                <!--                {{item.props.title.id}}-->
                <v-btn v-on:Click="open_order(item.props.title)" color="#000">Открыть</v-btn>
            </template>
            <!--            <template v-slot:[`item.contacts_phone`]="{item}">-->
            <!--                                {{-->
            <!--                    item.props.title.contacts_phone.map(i => {-->
            <!--                        return i.values.map(row => {-->
            <!--                            return row.value-->
            <!--                        }).join(', ');-->
            <!--                    }).join(', ')-->
            <!--                }}-->
            <!--            </template>-->
        </v-data-table>
        </span>

        <span v-if="selected_order">

        <v-row no-gutters>
          <v-col cols="7">
            <v-btn
                class="mb-2 mr-1"
                block
                variant="tonal"
                color="#00ff00"
                v-on:click="done"
            >
            Заказ изготовлен и выдан
            </v-btn>
          </v-col>
          <v-col cols="5">
              <v-btn
                  block
                  class="ml-1 mb-2"
                  variant="tonal"
                  color="#ff0000">
                Клиент отказался (отменить заказ)
              </v-btn>
            </v-col>
        </v-row>

        <v-row align="center" justify="center" no-gutters>
          <v-col cols="6" class="text-center justify-center">
<!--            {{ 'https://cartelye.ru/' + svg_a }}-->
            <a :href="'https://cartelye.ru/' + svg_a"
               target="_blank">Фронтальная сторона {{ 'https://cartelye.ru/' + svg_a }}</a>
              <!--            <v-btn density="compact" icon="mdi-arrow-down-bold-box" class="ml-3" v-on:click='forceDownload(svg_a)'></v-btn>-->
              <v-img
                  class="bg-white"
                  height="300"
                  v-if="svg_a"
                  :src="'https://cartelye.ru/'+svg_a"
                  alt="">

                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                        color="grey-lighten-4"
                        indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
              </v-img>
          </v-col>
          <v-col cols="6" class="text-center justify-center">
<!--            {{ 'https://cartelye.ru/' + svg_b }}-->
                <a :href="'https://cartelye.ru/' + svg_b"
                   target="_blank">Обратная сторона {{ 'https://cartelye.ru/' + svg_b }}</a>
              <v-img
                  :id="svg_b"
                  class="bg-white"
                  height="300"
                  v-if="svg_b"
                  :src="'https://cartelye.ru/'+svg_b"
                  alt="">

                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                        color="grey-lighten-4"
                        indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
              </v-img>
          </v-col>
        </v-row>

              <v-table>
                <tbody>
                  <tr
                      v-for="item in keys_tr"
                      :key="item"
                  >
                      <td>{{ item.name }} </td>
                      <span v-if="item.value === 'custom_fields'">
                          <table>
                          <tr v-for="item2 in selected_order[item.value]" :key="item2">
                              <td>{{ item2 }}</td>
                          </tr>
                          </table>
                      </span>
        <td v-else> {{ selected_order[item.value] }}</td>
        </tr>
        </tbody>
        </v-table>
            <!--            {{ selected_order }}-->
        </span>

        <span v-if="flag_done">
            <div class="py-12 text-center">
          <v-icon
              class="mb-6"
              color="success"
              icon="mdi-check-circle-outline"
              size="128"
          ></v-icon>

          <div class="text-h4 font-weight-bold">Заказ успешно выдан!</div>
                <v-row>
                    <v-col cols="12">
                        <v-btn block
                               class="mt-3"
                               v-on:click="new_search">
                            Новый поиск заказа
                        </v-btn>
                    </v-col>
                </v-row>
        </div>
        </span>

        <!--        {{ (search_result) ? search_result : '' }}-->
    </v-form>

</template>

<script>
import Search from '.'

export default {
    name: 'SearchForm',

    data: () => ({
        keys_tr: [
            {
                name: 'id',
                value: 'id'
            }, {
                name: 'name',
                value: 'name'
            }, {
                name: 'Дата заказа',
                value: 'created_at'
            }, {
                name: 'Тип заказа',
                value: 'pipeline'
            }, {
                name: 'Дополнительные поля',
                value: 'custom_fields'
            }, {
                name: 'Цена',
                value: 'price'
            }, {
                name: 'Имя',
                value: 'contacts_name'
            }, {
                name: 'Контакты',
                value: 'contacts_phone'
            }
        ],
        svg_a: null,
        svg_b: null,
        valid: true,
        number_order: null,
        number_phone: null,
        agree: true, //todo change
        search_result: null,
        selected_order: null,
        itemsPerPage: 5,
        headers: [
            // {
            //     title: 'Название заявки',
            //     align: 'start',
            //     sortable: false,
            //     key: 'name',
            // },
            {
                title: 'Имя',
                align: 'start',
                sortable: false,
                key: 'contacts_name',
            },
            {
                title: 'Контакт',
                align: 'start',
                sortable: false,
                key: 'contacts_phone',
            },
            {title: 'Тип', align: 'end', key: 'pipeline'},
            {title: 'Дата создания', align: 'end', key: 'created_at'},
            {title: '', align: 'end', key: 'open'},
        ],
        loading: true,
        loading_result: false,
        flag_done: false,
        error_404: false
    }),
    methods: {
        // forceDownload(link){
        //     console.log(link)
        //     console.log(document.getElementsByClassName('v-img__img'))
        //     console.log(document.getElementsByClassName('v-img__img')?[0].outerHTML : 'null')
        //     let svgData = document.getElementsByClassName('v-img__img')?[0].outerHTML : 'null';
        //     let svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});
        //     let url = 'https://cartelye.ru/' + link;
        //     let fileName = link;
        //     let xhr = new XMLHttpRequest();
        //     xhr.open("GET", url, true);
        //     xhr.responseType = "blob";
        //     xhr.onload = function(){
        //         let tag = document.createElement('a');
        //         tag.href = svgBlob;
        //         tag.download = fileName;
        //         document.body.appendChild(tag);
        //         tag.click();
        //         document.body.removeChild(tag);
        //     }
        //     xhr.send();
        // },
        rule() {
            return !!(this.number_phone || this.number_order) || "Нужен номер заказа или телефона"
        },
        async validate() {
            const {valid} = await this.$refs.form.validate()

            if (valid) {
                this.loading = true;
                this.error_404 = false;
                if (!this.search_result)
                    this.loading_result = true;
                Search.search_order(this, {number_phone: this.number_phone, number_order: this.number_order})
            }
        },
        clear() {
            this.agree = false;
            this.search_result = null;
            this.error_404 = false;
            this.$refs.form.reset()
        },
        clear_validate() {
            this.$refs.form.resetValidation()
        },
        open_order(item) {
            if (item.pipeline_id === 6746270) {
                this.$emit('set_new_tab_name', item.name);
                this.selected_order = item;
                // console.log(this.selected_order['custom_fields_values'].find(el => {
                //     return el['field_name'] === "Svg фронт"
                // })['values'][0]['value'])
                this.svg_a = this.selected_order['custom_fields_values'].find(el => {
                    return el['field_name'] === "Svg фронт"
                })['values'][0]['value'].replace('https:/cartelye.ru/', '').replace('https://cartelye.ru/', '');
                this.svg_b = this.selected_order['custom_fields_values'].find(el => {
                    return el['field_name'] === "Svg бэк"
                })['values'][0]['value'].replace('https:/cartelye.ru/', '').replace('https://cartelye.ru/', '');
                Search.change_status(this, item.id, '57057074', this.$NAME_TT); // шаблонный, открыт на ТТ
            } else if (item.pipeline_id === 6766090) {
                this.$emit('set_new_tab_name', item.name);
                this.selected_order = item;
                // this.svg_a = this.selected_order['custom_fields_values'].find(el => {
                //     return el['field_name'] === "Svg фронт"
                // }) ? ['values'][0]['value'] : null;
                // this.svg_b = this.selected_order['custom_fields_values'].find(el => {
                //     return el['field_name'] === "Svg бэк"
                // }) ? ['values'][0]['value'] : null;
                Search.change_status(this, item.id, '57212886', this.$NAME_TT); // индивидуальный, открыт на ТТ
            } else {
                alert("Неверный статус заказа")
            }
        },
        done() {
            if (this.selected_order.pipeline_id === 6746270) {
                Search.change_status(this, this.selected_order.id, '142', this.$NAME_TT); // шаблонный, открыт на ТТ
            } else if (this.selected_order.pipeline_id === 6766090) {
                Search.change_status(this, this.selected_order.id, '142', this.$NAME_TT); // индивидуальный, открыт на ТТ
            } else {
                alert("Неверный статус заказа")
            }
        },
        new_search() {
            this.$emit('new_search');
        }
    }
}
</script>


<style scoped>
</style>