<template>
    <v-toolbar color="#000">
        <v-toolbar-title>
            <v-img
                :src="require('../assets/header.png')"
                class="my-3"
                contain
                height="200"
            />
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <span id='ct' class="text-center"></span>

        <v-spacer></v-spacer>

        {{ this.$NAME_TT }}

        <v-menu>
            <template v-slot:activator="{ props }">
                <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
            </template>

            <v-list>
                <v-list-item>
                    <v-list-item-title v-on:click="logout">Выход</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <template v-slot:extension>
            <v-tabs
                v-model="tab"
                align-tabs="title"
            >
                <v-tab
                    v-for="item in items"
                    :key="item.value"
                    :value="item.value"
                >
                    {{ item.name }}

                    <v-dialog
                        v-model="item.dialog"
                        persistent
                        width="auto"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                class="ml-2"
                                size="x-small"
                                style="margin-right: -10px; color: rgb(255,110,139)"
                                icon
                                v-if='items.length>2 && item.value.indexOf("search") !== -1'
                            >
                                <v-icon>
                                    mdi-close-box
                                </v-icon>
                                <v-tooltip
                                    activator="parent"
                                    location="bottom"
                                >Закрыть вкладку
                                </v-tooltip>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="text-h5">
                                Вы уверены что хотите закрыть вкладку?
                            </v-card-title>
                            <v-card-text>Проверьте что на текущей вкладке вы завершили работу с заказом</v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="green-darken-1"
                                    variant="text"
                                    @click="item.dialog = false"
                                >
                                    Отмена
                                </v-btn>
                                <v-btn
                                    color="green-darken-1"
                                    variant="text"
                                    @click="close_tab(item.value)"
                                >
                                    Закрыть вкладку
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-tab>
                <v-tab
                    key="new_tab"
                    value="new"
                    @click="new_search(false)"
                >
                    +
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >Новый поиск заказа
                    </v-tooltip>
                </v-tab>
            </v-tabs>
        </template>
    </v-toolbar>

    <v-window v-model="tab">

        <v-window-item value="home">
            <v-row class="text-center">
                <v-col cols="12">
                    <v-img
                        :src="require('../assets/header.png')"
                        class="my-3"
                        contain
                        height="150"
                    />
                </v-col>
                <v-col cols="12">
                    <v-btn @click="() => {this.tab = this.items[1].value}">
                        Перейти к поиску
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-expansion-panels multiple class="w-50" style="left: 25%">
                        <v-expansion-panel>
                            <v-expansion-panel-title>
                                Полезные материалы
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <a href="https://cartelye-tt.site/docs/1C.pdf" target="_blank">Инструкция по работе с
                                    1С</a> <br/>
                                <a href="https://cartelye-tt.site/docs/sales_rules.pdf" target="_blank">Техника продаж
                                    CARTELYE</a> <br/>
                            </v-expansion-panel-text>
                        </v-expansion-panel>

                        <v-expansion-panel class="mb-5">
                            <v-expansion-panel-title>
                                Связь с нами в случае невозможности решить проблему самостоятельно
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <v-table theme="dark">
                                    <thead>
                                    <tr>
                                        <th class="text-center">
                                            Ответственный
                                        </th>
                                        <th class="text-center">
                                            Описание
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td class="pt-3 pb-3">Дорофеев Матвей <br/>
                                            <a href="tel:+79213406272">+79213406272</a> <br/>
                                            <a href="https://t.me/mattdorofeev" target="_blank">t.me/mattdorofeev</a>
                                        </td>
                                        <td>По вопросам касаемо Лазера, настроек программы для лазера, оборудованию для
                                            лазера
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pt-3 pb-3">Казаков Константин <br/>
                                            <a href="tel:+79180214748">+79180214748</a> <br/>
                                            <a href="https://t.me/kostjakaz" target="_blank">t.me/kostjakaz</a>
                                        </td>
                                        <td> По вопросам по ноутбуку, кассе, сайту https://cartelye.ru/ и админке
                                            https://cartelye-tt.site/
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pt-3 pb-3">Дежурный менеджер, клиентский сервис<br/>
                                            Телефон компании <a href="tel:+79312222333">+79312222333</a> <br/>
                                        </td>
                                        <td> По вопросам заказов, Данных клиентов из заказа <br/>
                                            Обязательно сразу представьтесь, что вы с нашей торговой точки и какой
                                            конкретно
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pt-3 pb-3">Михалёва Ольга дизайнер<br/>
                                            <a href="tel:+79811511391">+79811511391</a> <br/>
                                            <a href="https://t.me/olgamix_art" target="_blank">t.me/olgamix_art</a>
                                            <br/> <br/>
                                            Булатбекова Асель дизайнер<br/>
                                            <a href="tel:+79291043326">+79291043326</a> <br/>
                                            <a href="https://t.me/Asssseli" target="_blank">t.me/Asssseli</a>
                                        </td>
                                        <td> По вопросам дизайнов, но лучше обращаться через телеграм чат Вашей ТТ
                                        </td>
                                    </tr>
                                    </tbody>
                                </v-table>
                            </v-expansion-panel-text>
                        </v-expansion-panel>

                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-window-item>

        <v-window-item
            v-for="item in items.slice(1)"
            :key="item.value"
            :value="item.value">
            <v-layout class="d-flex justify-center mb-6">
                <search-form v-on:set_new_tab_name="updateName" v-on:new_search="()=> new_search(true)"></search-form>
            </v-layout>
        </v-window-item>

    </v-window>
</template>
<script>
import {v4 as uuidv4} from 'uuid';
import SearchForm from "@/components/SearchForm/SearchForm";

let timer_id = null;

export default {
    name: 'StartPage',
    components: {SearchForm},
    data: () => ({
        tab: null,
        items: [
            {
                name: 'Начальная страница',
                dialog: false,
                value: "home"
            },
            {
                name: 'Поиск заказа',
                dialog: false,
                value: "search"
            },
        ],
    }),
    mounted() {
        function display_ct() {
            let options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                timezone: 'UTC'
            };
            let CDate = new Date()
            let NewDate = CDate.toLocaleString("ru", options);
            NewDate = `<span style="font-size: 24px; font-weight: 900;">${CDate.toLocaleTimeString("ru", {
                hour: '2-digit',
                minute: '2-digit'
            })}</span><br/>${NewDate}`;
            document.getElementById('ct').innerHTML = NewDate;
            display_c();
        }

        function display_c() {
            let refresh = 30000; // Refresh rate 30 sec
            timer_id = setTimeout(display_ct, refresh)
        }

        display_ct();
        display_c();
    },
    beforeUnmount() {
        clearTimeout(timer_id);
    },
    methods: {
        updateName(new_name) {
            let find_index = this.items.findIndex(value => {
                return value.value === this.tab
            });
            this.items[find_index].name = new_name;
        },
        new_search(flag_done = false) {
            if (flag_done) {
                this.close_tab(this.tab);
            }
            let new_index = "search" + uuidv4();
            this.items.push(
                {
                    name: 'Поиск заказа',
                    value: new_index
                }
            );
            this.tab = new_index;
        },
        close_tab(index) {
            let find_index = this.items.findIndex(value => {
                return value.value === index
            });
            this.items.splice(find_index, 1)
            let lastElement2 = this.items.slice(-1);
            setTimeout(() => {
                this.tab = lastElement2[0].value
            }, 200)
        },
        logout() {
            let now = new Date();
            let time = now.getTime();
            let expireTime = time + 1000 * 3600;
            now.setTime(expireTime);
            document.cookie = 'auth=bad;expires=' + now.toUTCString() + ';path=/';
            this.$SET_AUTH(false);
            this.$emit('update');
        }
    }
}
</script>

<style scoped>
</style>
