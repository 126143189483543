<template>
    <v-sheet max-width="600" width="420" class="mx-auto mt-10">
        <v-form
            v-model="form" @submit.prevent="onSubmit" class="text-center">
            <h2>CARTELYE ADMIN</h2>
            <v-text-field
                v-model="password"
                prepend-inner-icon="mdi-lock-outline"
                variant="outlined"
                :type="show1 ? 'text' : 'password'"
                label="Password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                :rules="[required]"
                class="mr-3 mt-2"
            ></v-text-field>
            <span v-if="wrong" class="text-red-accent-1">
                Неверный пароль
            </span>
            <v-btn
                block
                :disabled="!form"
                class="mt-2"
                text="Войти"
                type="submit"
            ></v-btn>
        </v-form>
    </v-sheet>
</template>

<script>
import {sha224} from "js-sha256";

export default {
    name: 'AuthComponent',
    data: () => ({
        show1: false,
        form: false,
        password: null,
        wrong: false
    }),
    mounted() {
    },
    beforeUnmount() {
    },
    methods: {
        onSubmit() {
            if (!this.form) return
            let index = this.$TT.findIndex(o => o.$PASS === sha224(this.password));
            if (index !== -1) {
                this.wrong = false;
                let now = new Date();
                let time = now.getTime();
                let expireTime = time + 1000 * 36000;
                now.setTime(expireTime);
                document.cookie = 'auth=ok;expires=' + now.toUTCString() + ';path=/';
                document.cookie = 'NameTT='+this.$TT[index].$NAME_TT +';expires=' + now.toUTCString() + ';path=/';
                this.$SET_AUTH(true);
                this.$SET_NAME_TT(this.$TT[index].$NAME_TT);
                this.$emit('update');
                // this.$forceUpdate();
            } else {
                this.wrong = true;
            }
        },
        required(v) {
            return !!v || ''
        },
    }
}
</script>

<style scoped>
</style>
