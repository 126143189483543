import {createApp} from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import {loadFonts} from './plugins/webfontloader'
// import axios from 'axios';

loadFonts()

import data from './property.js';

let app = createApp(App)
    .use(vuetify)

////auth
let allCookies = document.cookie;
let cookieValueName = document.cookie
    .split("; ")
    .find((row) => row.startsWith("NameTT="))
    ?.split("=")[1];
app.config.globalProperties['$AUTH'] = allCookies.indexOf('auth=ok') !== -1;
app.config.globalProperties['$NAME_TT'] = cookieValueName;
app.config.globalProperties['$SET_AUTH'] = (flag) =>{
    app.config.globalProperties['$AUTH'] = flag;
};
app.config.globalProperties['$SET_NAME_TT'] = (name) =>{
    app.config.globalProperties['$NAME_TT'] = name;
};


for (let rec in data) {
    app.config.globalProperties[rec] = data[rec];
}


// axios.defaults.headers['Authorization'] = `Bearer ${data.$access_token}`;
// axios.defaults.crossDomain = true;

app.mount('#app')
